
.fr-dib {
    float: none;
    vertical-align: top;
}

.fr-fic {
    margin-left: auto;
    margin-right: auto;
}

.fr-fil {
    margin-left: 0;
}

.fr-fir {
    margin-right: 0;
}
