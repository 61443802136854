
.webgl-content .logo, .u-progress, .webgl-content button {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
}

.webgl-content .logo {
    background: url('../../img/atelier-duchu-logo.svg') no-repeat center / contain;
    width: 256px;
    height: 256px;
}

.webgl-content .u-progress {
    height: 18px; width: 141px; margin-top: 190px;
}

.webgl-content .u-progress .empty {
    background: url('../../img/progressEmpty.Light.png') no-repeat right / cover;
    float: right;
    width: 100%;
    height: 100%;
    display: inline-block;
}

.webgl-content .u-progress .full {
    background: url('../../img/progressFull.Light.png') no-repeat left / cover;
    float: left;
    width: 0%;
    height: 100%;
    display: inline-block;
}

.webgl-content button {
    margin-top: 190px;
}

.webgl-content .logo.Dark { background-image: url('../../img/atelier-duchu-logo-white.svg') }

.webgl-content .u-progress.Dark .empty { background-image: url('../../img/progressEmpty.Dark.png') }

.webgl-content .u-progress.Dark .full { background-image: url('../../img/progressFull.Dark.png') }

.webgl-content .footer {
    margin-top: 5px;
    height: 38px;
    line-height: 38px;
}

.webgl-content .footer .fullscreen {
    height: 100%;
    display: inline-block;
    background: transparent center no-repeat;
    cursor: pointer;
}

.webgl-content .footer .fullscreen {
    background-image: url('../../img/fullscreen.png');
    width: 38px;
    float: right;
    margin-right: 5px;
}
