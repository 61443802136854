.blog-header-info {
    display: flex;

    flex-direction: row;
    justify-content: space-between;

    h2>a { text-decoration: none !important; }
    .left {
        max-width: 60%;

        @include media-breakpoint-up(md) {
            max-width: 50%;
        }
    }
    .right { max-width: 40%; }
}

.blog-list-item {
    @extend .card;

    margin-bottom: 1rem;

    img { width: 100%; }

    .title-image {
        @extend .card-image;

        height: 200px;
    }

    .content {
        line-height: 1.75;
    }
}

.blog-category-list {
    li { display: inline-block }
}

.blog-post {
    .content {
        @extend .font-content;

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
            object-fit: cover;

            display: block;
        }

        audio, video, img, iframe {
            width: 100%;
            margin-bottom: $spacer * .5;

            &+p {
                margin-top: $spacer * .5;
            }
        }

        h2, h3 {
            margin-bottom: 1rem;
            margin-top: 1rem;
        }

    }
}
