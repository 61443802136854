
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zindex-modal-backdrop;

    background-color: rgba($body-color, .75);

    pointer-events: none;
    opacity: 0;

    transition: opacity .2s ease;

    &.visible {
        opacity: 1;
        pointer-events: all;
    }

}

.modal-content {
    margin-top: $nav-link-height + ($spacer * 3);
}
