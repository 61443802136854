
footer.site {
    flex: 1 0;

    background-color: $d-footer-bg;
    color: $d-footer-fg;

    a {
        color: $d-footer-fg;

        &:hover {
            color: lighten($d-footer-fg, 15%);
        }
    }

    hr {
        border-color: fade-out($d-footer-fg, .5);
    }

    .group-header {
        font-weight: $font-weight-bold;
    }
}

.nav-footer {
    .nav-link {
        padding: 0;
    }

}
