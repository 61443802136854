.icon {
    display: inline-block;
    margin: 0 .25rem;

    width: 20px;

    &.icon-32 {
        width: 32px;
    }

}

.link-icon {
    text-decoration: none !important;
}
