
@import "glightbox/dist/css/glightbox.css";

.clickable {
    cursor: pointer;
}

.glightbox-clean {

    .gslide-title {
        color: $d-footer-fg;
        margin-bottom: 0;
    }

    .gslide-description {
        background: $d-footer-bg;
    }

}
