.navbar-site {
    $navbar-nav-desired-height: $nav-link-height;
    $navbar-nav-border-color: $gray-300;

    @extend .mb-4;
    @extend .mb-lg-5;

    @extend .navbar-expand-lg;
    @extend .navbar-light;
    @extend .border-top;
    @extend .border-bottom;

    .logo {
        width: auto;
        height: 64px;
    }

    .navbar-nav {
        .nav-item:first-child {
            border-left: 1px $navbar-nav-border-color solid;
        }

        .nav-item:not(:first-child) {
            border-left: 1px $navbar-nav-border-color solid;
        }

        .nav-item:last-child {
            border-right: 1px $navbar-nav-border-color solid;
        }

        .nav-item {
            @include media-breakpoint-down(md) {
                border: none !important;
            }
        }

        .nav-item.has-items {
            position: relative;
        }

        .nav-link {
            padding: calc((#{$navbar-nav-desired-height} - #{$font-size-base * $line-height-base}) / 2) $navbar-nav-link-padding-x;

            @include media-breakpoint-down(md) {
                padding: $spacer $spacer $spacer 0;

            }
        }

        .dropdown-menu {
            @include media-breakpoint-down(md) {
                border: none;
                margin: 0;
            }

            .dropdown-item {
                @include media-breakpoint-down(md) {
                    background-color: transparent;

                    &.active {
                        font-weight: $font-weight-bold;
                    }
                }

            }
        }

    }

    .language-selector-link {
        color: $navbar-light-color;
        padding: calc(((#{$navbar-nav-desired-height} - #{$font-size-base * $line-height-base}) / 2)/8) $navbar-nav-link-padding-x;

        &.active {
            font-weight: $font-weight-bold;
        }
    }

}
