
html, body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

audio, video {
    width: 100%;
}

main.site {
    flex: 1 0 auto;
}

@include media-breakpoint-down(md) {
    .container {
        width: calc(100% - #{$spacer});
    }
}
