
.card {
    @extend .bg-white;
    @extend .shadow-sm;
    @extend .position-relative;

    $card-image-height: $spacer * 14;
    $card-padding: $spacer * 1.5;

    margin: 0 0 map-get($spacers, 4) 0;
    padding: ($card-padding + $card-image-height) $card-padding $card-padding $card-padding;

    font-size: $font-size-base;

    .card-image {
        @extend .position-absolute;
        @extend .mb-4;

        top: 0;
        left: 0;

        width: 100%;
        height: $card-image-height;
        object-fit: cover;

    }

    .card-footer {
        margin: 0 (-$card-padding) (-$card-padding) (-$card-padding);
        padding: $card-padding;
        background-color: $gray-100;
    }

    .small-bold-heading {
        margin-bottom: 1rem;
    }

    @include media-breakpoint-up(md) {
        margin: 0 0 map-get($spacers, 5) 0;
    }

}
