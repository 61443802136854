:root, html, body {
    font-size: 16px !important;
}

.fr-wrapper {
    @import "variables";
    @import "bootstrap";
    @import "utility";
    @import "type";
    @import "list";
    @import "layout";
    @import "site_title";
    @import "blog";
    @import "unity";
    @import "cards";
    @import "news";
    @import "icons";
    @import "froala_support";
    @import "lightbox";

}

.fr-element.fr-view {
    max-width: 1140px;
    left: 50% !important;
    transform: translateX(-50%);

}
