.picture-entry {
    margin-bottom: $spacer * 4;

    vertical-align: middle;
    position: relative;

    display: flex;
    justify-content: center;

    user-select: none;

    img {
        @extend .shadow;
        @extend .position-absolute;

        left: 50%;
        top:  calc(50% + 45px);

        transform: translate(-50%, -50%);

        max-width:  100%;
        max-height: 80vh;
        object-fit: contain;
    }

    .img-nav {
        position: absolute;
        top: 50%;
        opacity: .5;
        cursor: pointer;
        padding: 1rem;
        transition: opacity ease .2s;
        z-index: $zindex-dropdown;

        &.right {
            right: 0;
        }

        &:hover {
            opacity: .9;
        }
    }

    .img-wrapper {
        height: 80vh;
    }

    .loading {
        @extend .h1;
        @extend .position-absolute;

    }

    .image-title {
        @extend .position-absolute;
        @extend .font-content;

        left: 50%;
        transform: translateX(-50%);
        top: 100%;
    }

}
