@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Roboto+Mono&display=swap');

.small-heading {
    font-size: 18px;
}

.small-bold-heading {
    font-size: 18px;
    font-weight: $font-weight-bold;
}

.font-content {
    font-size: $d-font-size-content;
}

.prose {
    @extend .font-content;
    width:     65ch;
    max-width: 100%;
}
