
.site-title {
    @extend .border-bottom;

    width: 100%;
    height: 70vh;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    margin-top: $spacer * -3 !important;

    @include media-breakpoint-down(lg) {
        margin-top: $spacer * -1.5 !important;
    }

}

.site-title-unity {
    background-color: #231F20;
    position: relative;
    overflow: hidden;

    background-position: 50% 50% !important;
    background-size: cover !important;
}
